<template>
	<div
		class="flex items-center text-base text-left mx-auto py-2.5 hover:bg-gray-200 hover:shadow-inner"
		@click="handleGeoLocation"
	>
		<img
			src="@/assets/icons/gps.svg"
			alt="Current Location"
			class="mr-2.5 ml-2 md:ml-0"
		>
		Current Location
	</div>
</template>

<script async>
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'

import { API_SERVER } from '@/constants/index.js'
import { ERROR } from '@/constants/toast/type.js'
import { logError } from '@/utils/error-handling.js'

export default {
	emits: [ 'update-is-loading' ],
	data() {
		return {
			isLoading: false
		}
	},
	computed: {
		...mapGetters('search', [ 'highlightedIndex', 'highlightedRoute' ])
	},
	watch: {
		isLoading() {
			this.$emit('update-is-loading', this.isLoading)
		}
	},
	methods: {
		...mapMutations('toast', [ 'showToast' ]),
		handleGetLocationFailed(error) {
			this.isLoading = false
			this.showToast({
				primaryText: 'Error',
				secondaryText: 'We were unable to find your location',
				type: ERROR
			})
			logError(error)
		},
		handleRedirect(response) {
			this.isLoading = false
			if (this.$route?.fullPath === response.data.redirect) return
			this.$router.push(response.data.redirect)
		},
		async fetchLocation(lat, lon) {
			let errorMsg = ''
			const response = await axios.get(`${API_SERVER}/set_coords.php?ajax=true&lat=${lat}&lon=${lon}`)

			this.isLoading = false

			if (!response.data) {
				errorMsg = 'Invalid response from server'
			} else if (response.data?.redirect) {
				this.handleRedirect(response)
				return
			} else if (response.data?.msg) {
				errorMsg = response.data.msg
			}
			this.handleGetLocationFailed(errorMsg)
		},
		async handleCurrentPosition(position) {
			const lat = position.coords?.latitude
			const lon = position.coords?.longitude
			this.fetchLocation(lat, lon)
		},
		handleGeoLocation() {
			if (navigator.geolocation) {
				this.isLoading = true
				navigator.geolocation.getCurrentPosition(this.handleCurrentPosition, this.handleGetLocationFailed)
			} else {
				this.handleGetLocationFailed(new Error('Geolocation is not supported by this browser.'))
			}
		}
	}
}
</script>
